.site {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;
  margin: 1px;
  min-width: 50px;
  text-align: center;
}
.site p.name {
  margin: 0 auto 4px;
  padding: 0;
  text-align: center;
  font-family: "Helvetica", Arial, sans-serif;
  font-size: 14px;
}
.site img {
  width: 45px;
  height: 45px;
}
