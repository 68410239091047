#trolley-eta {
  width: 100%;
}

div#trolley-eta {
  text-align: center;
}

#trolley-eta #trolley-eta__container {
  width: 100%;
}

#trolley-eta #trolley-eta__container h3 {
  color: var(--light-brick);
  padding: 0px;
  margin: 0px;
}

#trolley-eta #trolley-eta__container .table {
  margin: 5px 10px;
}

#trolley-eta #trolley-eta__container>.table .tr {
  display: flex;
  justify-content: center;
}

#trolley-eta #trolley-eta__container .table .td {
  padding: 5px 0;
}

#trolley-eta #trolley-eta__container>.table .align-left.td {
  width: 65%;
}

#trolley-eta #trolley-eta__container>.table .align-right.td {
  width: 35%;
}