#trolley-tracker {
  box-sizing: border-box;
  display: block;
  border-radius: 0%;
  margin: 0;
  background: #f8f8f8;
}

#trolley-tracker .map {
  height: 100vh;
}

#trolley-tracker img#apu-logo {
  position: absolute;
  z-index: 9999;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  width: 30%;
  max-width: 200px;
  padding: 7px;
  margin: 3px;
}

#trolley-tracker #tracking-info {
  position: absolute;
  z-index: 9999;
  bottom: 0;
  right: 0;
  width: 234px;
  display: flex;
  column-gap: .5em;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 7px 0px;
  margin-bottom: 20px;
  margin-right: 20px;
}

#trolley-tracker #tracking-info div {
  color: var(--brick-red);
  font-weight: bold;
  font-size: 11px;
  font-family: sans-serif, arial, monospace;
}

#trolley-tracker #tracking-info .vert-bar {
  /* display: block */
  padding-right: .7em;
  border-right: 2px solid var(--brick-red);
}

#trolley-tracker #trolley-eta {
  position: absolute;
  z-index: 9999;
  bottom: 0;
  right: 0;
  width: 220px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  color: var(--black);
  font-weight: bold;
  font-size: 14px;
  font-family: sans-serif, arial, monospace;
  padding: 7px;
  margin-bottom: 70px;
  margin-right: 20px;
}

#trolley-tracker .custom-alert {
  position: absolute;
  z-index: 99999;
  top: 0;
  right: 0;
  left: 0;
  background-color: #e7f520;
  color: #000000;
  font-family: sans-serif;
  font-size: 25px;
  font-weight: bold;
}

#trolley-tracker .custom-alert p {
  padding-right: 20px;
}

#trolley-tracker #closebtn {
  font-family: sans-serif, arial, monospace;
  float: right;
  font-size: 30px;
  line-height: 0.5;
  cursor: default;
  color: #757575;
  background: transparent;
  border: none;
}

/* Hover is not supported on phones */
#trolley-tracker #closebtn:hover {
  color: #000000;
}

#trolley-tracker .leaflet-div-icon {
  background-color: transparent;
  border-color: transparent;
}

#trolley-tracker #snow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}