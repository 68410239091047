#campus-work-order body {
  height: 100%;
}

#campus-work-order .header {
  display: flex;
  font-family: "Helvetica", serif;
  justify-content: space-around;
  margin: 1em;
}

#campus-work-order .column {
  flex: 33.3%;
  width: 100%;
}

#campus-work-order .content {
  padding-bottom: 20px;
}

#campus-work-order #empty {
  width: 240px;
}

#campus-work-order #logo {
  max-width: 250px;
  padding: 10px;
  float: left;
  width: 100%;
}

#campus-work-order .alert {
  text-align: center;
  margin: 20px auto;
  padding: 1em;
  border-radius: 10px;
  background-color: var(--bone-white);
  width: 700px;
  max-width: 80%;
  font-family: "Helvetica", Arial, sans-serif;
}

#campus-work-order #alertmess {
  margin: 20px;
  font-family: "Helvetica", Arial, sans-serif;
  line-height: 2;
}

#campus-work-order #btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#campus-work-order #btns>label {
  padding: 0px 10px;
}

#campus-work-order #submit {
  margin: 0 auto;
}

#campus-work-order #emergencies,
#campus-work-order #notemergencies {
  margin: 0 10px 20px;
}

#campus-work-order .header h1 {
  margin-top: 80px;
  color: var(--brick-red);
  font-size: 40px;
  text-align: center;
}

#campus-work-order p {
  margin-top: 0;
  text-align: center;
  color: var(--slate-black);
  font-family: "Roboto", serif;
}

#campus-work-order a {
  text-align: center;
}

#campus-work-order #order {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 60px;
  justify-content: center;
}

#campus-work-order .needhelp {
  margin: 50px auto 30px;
  text-align: center;
  text-decoration: underline;
}

#campus-work-order .needhelp a {
  color: var(--brick-red);
  transition: color 0.3s;
}

#campus-work-order .needhelp a:hover,
#campus-work-order .needhelp a:focus {
  color: var(--vibrant-red);
}

#campus-work-order .contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto 100px;
  width: min(50%, 800px);
  background: var(--bone-white);
  border-radius: 10px;
  padding: 20px 50px;
}

#campus-work-order .contact div {
  text-align: center;
  line-height: 25px;
}

#campus-work-order .contact .after-hours {
  margin-top: 20px;
}

/* 1000px - Stack header for smaller Screen */
@media only screen and (max-width: 1000px) {
  #campus-work-order .header {
    flex-flow: column;
  }

  #campus-work-order #logo {
    max-width: 200px;
    margin: auto;
  }

  #campus-work-order #empty {
    height: 0;
  }

  #campus-work-order .header h1 {
    margin-top: 30px;
  }
}

/* 600px */
@media only screen and (max-width: 600px) {
  #campus-work-order .content {
    margin-top: 150px;
  }

  #campus-work-order .header h1 {
    font-size: 31px;
    margin-top: 10px;
  }

  #campus-work-order p {
    margin-top: -150px;
    text-align: center;
  }

  #campus-work-order img {
    width: 300px;
    height: 160px;
    position: relative;
  }

  #campus-work-order #east,
  #campus-work-order #west,
  #campus-work-order #regional {
    position: absolute;
    width: 260px;
    margin-top: 120px;
    background: var(--black);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 10px 10px;
    text-align: center;
  }
}

@media only screen and (max-width: 400px) {
  #campus-work-order img {
    border-radius: 0;
  }

  #campus-work-order .button {
    border-radius: 0 !important;
  }

  #campus-work-order .after-hours {
    margin-top: -30px;
  }
}