/* * {
  font-family: sans-serif;
} */

#banner-form {
  --panel-back: #5aa3f5;
  --button-shadow: #888;
  height: 100%;
  max-width: 100%;
  min-width: 310px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 20px;
}

#banner-admin h1 {
  text-align: left;
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: normal;
}

#banner-admin h2 {
  margin-top: 40px;
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 1.5em;
}

#banner-admin .banner-message {
  margin: 10px 5px;
}

#banner-admin .banner-message p {
  margin: 0;
}

#form-title p {
  margin: 10px 5px;
  font-size: 18px;
}

#banner-admin .banner-dates,
#banner-admin .radio-button {
  text-align: center;
  border-radius: 8px;
  -moz-box-shadow: 1px 1px 2px var(--button-shadow);
  -webkit-box-shadow: 1px 1px 2px var(--button-shadow);
  box-shadow: 1px 1px 1px var(--button-shadow);
  outline: none;
  border: 1px solid #dddddd;
}

#banner-admin .banner-dates:focus {
  border: 2px solid var(--brick-red);
  -moz-box-shadow: 1px 1px 2px #a88;
  -webkit-box-shadow: 1px 1px 2px #a88;
  box-shadow: 1px 1px 1px #a88;
}

#banner-admin .banner-dates:hover {
  border: 2px solid #000;
  -moz-box-shadow: 1px 1px 2px #4f4f4f;
  -webkit-box-shadow: 1px 1px 2px #4f4f4f;
  box-shadow: 1px 1px 1px #4f4f4f;
}

#banner-admin label {
  font-size: 20px;
  margin-left: 10px;
  margin-top: 5px;
}

#banner-admin .banner-messages-card {
  display: flex;
  flex-direction: column;
  background: var(--panel-back);
  margin-bottom: 20px;
  padding: 12px;
  -moz-box-shadow: 1px 1px 5px #aaa;
  -webkit-box-shadow: 1px 1px 5px #aaa;
  box-shadow: 1px 1px 5px #aaa;
  border-radius: 8px;
  position: relative;
}

#banner-admin #date-inputs {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

#banner-admin #dates-start {
  margin-right: 70px;
}

#banner-admin #dates p {
  margin: 5px;
  padding: 0;
}

#banner-admin .banner-dates {
  width: 300px;
  height: 55px;
  border: solid black 2px;
  text-align: left;
  font-size: 25px;
  padding-left: 5px;
}

#banner-admin #announcement-locations {
  margin: 30px 0;
  flex-wrap: wrap;
  row-gap: 20px;
}

#banner-admin #button-container {
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  line-height: 30px;
  text-align: left;
  margin-bottom: 10px;
}

#banner-admin #button-container *:hover,
#button-container *:focus {
  cursor: pointer;
}

#banner-admin .radio-button,
.radio-button:hover,
.radio-button:focus {
  height: 30px;
  width: 30px;
  box-shadow: none;
  /* border: 2px solid var(--brick-red);
  border-radius: 50%; */
}

#banner-admin .radio-button:hover,
#banner-admin .radio-button:focus {
  border: 20px solid var(--brick-red);
  border-radius: 50%;
}

/* what */
#banner-admin .radio-button:before {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.5);
}

input[type="radio"]:checked:before {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 1);
}

/* end */

#banner-admin #submit-button:disabled {
  background: var(--button-shadow);
  border: 2px solid var(--button-shadow);
}

/* The custom alert banner-messages message box */
#banner-admin .custom-alert.banner {
  padding: 20px;
  color: #000;
  border-radius: 8px;
  background-color: var(--panel-back);
  word-wrap: break-word;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* The Trash icon to delete the banner-messages */
#banner-admin #delete-banner-container {
  position: absolute;
  right: 15px;
  bottom: 10px;
  border-radius: 50%;
  border: none;
  background-color: var(--panel-back);
}

#banner-admin #delete-banner {
  font-size: 25px;
  font-weight: 900;
  height: 25px;
}

#banner-admin #delete-banner:hover {
  color: var(--brick-red);
  cursor: pointer;
}