/* Main page container */
#virtual-id-one {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--bone-white);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  margin-top: auto;
  overflow: auto;
}

#virtual-id-one * {
  margin: 0;
  box-sizing: border-box;
  font-family: Inter, Helvetica, "Helvetica Neue", Arial, sans-serif !important;
  font-size: 14px;
}

#virtual-id-one h1 {
  font-size: 20px;
  margin: 0;
  line-height: 1;
  text-align: center;
  color: var(--black);
}

#virtual-id-one h1#name-header {
  font-size: 30px;
  padding-top: 25px;
  margin: 7px 0;
  color: var(--brick-red);
}

#virtual-id-one h1.account-name-dining {
  margin: 7px 0;
}

#virtual-id-one hr {
  width: 90%;
  margin: 5px 0;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  94% {
    transform: rotate(0deg);
  }

  96% {
    transform: rotate(-5deg);
  }

  98% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(-5deg);
  }
}

/* Card flip animation surface */
/* 
"duration"" is how long the whole animation is
"delay" is only for the first time it runs on page load
"direction" is set to alternate, which means the whole animation is 16s (8 forward, 8 back)
*/
#virtual-id-one .card-flip-animation-surface {
  margin: auto;
  padding-top: 10px;
  animation-name: wiggle;
  animation-duration: 8s;
  animation-delay: 8s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
  /* Shadow may create edge artifacts */
}

#virtual-id-one button#card-button {
  display: flex;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 10;
  border-radius: 24px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 10px;
}


#virtual-id-one img#icon-rotate {
  padding: 3px 12px 1px 6px;
  width: 4em;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 10px;
  background-color: var(--light-brick);
}

/* Card contents (front AND back container) */
#virtual-id-one .card-contents {
  width: 316px;
  height: 500px;
  max-width: 90vw;
  position: relative;
  transform-style: preserve-3d;
  transition: 0.6s 0.1s;
}

/* Card face (front OR back) */
#virtual-id-one .card-face {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 24px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#virtual-id-one .card-face button#card-button:hover,
#virtual-id-one .card-face button#card-button:focus {
  cursor: pointer;
  outline: 2px solid var(--slate-black);
}

/* Card face, front side */
#virtual-id-one .card-face#front {
  transform: rotateY(0deg);
  z-index: 1;
}

#virtual-id-one .card-face#front>div#upper {
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

#virtual-id-one .card-face#front>div#upper img#apu-logo {
  height: 25%;
  width: 60%;
  margin-top: 10px;
}

#virtual-id-one .card-face#front>div#upper img#alumn-icon {
  position: absolute;
  top: 28%;
  right: 32%;
  width: 25px;
  z-index: 9999;
}

#virtual-id-one .card-face#front>div#upper img#profile-picture {
  border-radius: 50%;
  height: 55%;
  margin: 10px;
  box-shadow: 1px 1px 5px rgb(0, 0, 0, 50%);
}

#virtual-id-one .card-face#front>div#upper #name-section {
  width: 100%;
}

#virtual-id-one .card-face#front>div#upper #name-section p#name {
  font-weight: bold;
}

#virtual-id-one .card-face#front>div#upper #name-section p#netid {
  color: #555;
}

#virtual-id-one .card-face#front>div#upper #name-section p#career {
  color: #555;
}

#virtual-id-one .card-face#front>div#lower {
  width: 100%;
  height: 50%;
  position: relative;
  top: 25%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

#virtual-id-one .card-face#front>div#lower div.titles {
  display: flex;
  flex-direction: row;
}

#virtual-id-one .card-face#front>div#lower div.title {
  font-weight: bold;
  margin-bottom: 5px;
  width: fit-content;
  flex-direction: column;
}

#virtual-id-one .card-face#front>div#lower div#alumni-title {
  font-weight: normal;
  margin-left: auto;
}

#virtual-id-one .card-face#front>div#lower div.sub-titles {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 4px;
}

#virtual-id-one .card-face#front>div#lower p.sub-title {
  color: #fff;
  background: #333;
  border-radius: 10em;
  padding: 4px 8px;
  font-size: 11px;
}

#virtual-id-one .card-face#front>div#lower div#barcode-section {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  bottom: 20px;
  overflow: hidden;
}

#virtual-id-one .card-face#front>div#lower div#barcode-section p#emplid {
  font-weight: 300;
  margin-bottom: -8px;
}

#virtual-id-one .card-face#front>div#lower div#barcode-section div#barcode {
  margin-bottom: -20px;
  max-width: 100%;
}

#virtual-id-one .card-face#front>div#lower div#barcode-section div#barcode svg {
  max-width: 100%;
}

#virtual-id-one div#suicide-lifeline-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 0;
}

#virtual-id-one div#suicide-lifeline-section>p#suicide-lifeline-label {
  font-size: 12px;
}

#virtual-id-one div#suicide-lifeline-section>a.suicide-lifeline-number {
  color: #fff;
  background: var(--light-brick);
  border-radius: 3em;
  padding: 4px 8px;
  margin: 8px;
  font-size: 12px;
}

#virtual-id-one div#suicide-lifeline-section>a.suicide-lifeline-number:focus,
#virtual-id-one div#suicide-lifeline-section>a.suicide-lifeline-number:hover {
  background: var(--brick-red);
  cursor: pointer;
}

/* Try hide / show */
#virtual-id-one .show-card {
  visibility: visible;
}

#virtual-id-one .hide-card {
  animation: 1s hideIt;
  animation-fill-mode: forwards;
}

@keyframes hideIt {
  99% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}

/* Card face, back side */
#virtual-id-one .card-face#back {
  transform: rotateY(180deg);
  z-index: 2;
}

#virtual-id-one .account,
#virtual-id-one .account .no-plan {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#virtual-id-one .account+.account {
  border-left: none;
}

#virtual-id-one .account .no-plan {
  height: 100%;

  h1 {
    padding-bottom: 5px;
  }

  p {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;
  }

  padding-top: 20%;
}

/* Buttons */
#virtual-id-one .button {
  line-height: 40px !important;
}

#virtual-id-one .button#support-link,
#virtual-id-one .button#add-cougar-bucks,
#virtual-id-one .button#hours-menu-button {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 10px;
  min-height: 38px;
  padding-top: 0px;
  width: 15em;
  z-index: 2;
}

/* Modal */
#virtual-id-one .modal {
  background: none;
  background-color: #0006;
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-items: center;
}

#virtual-id-one .modal-content {
  display: none;
  background: none;
  margin: 40px auto auto;
  width: 85%;
  height: 85%;
}

#virtual-id-one .modal-content #hours-iframe {
  height: 100%;
  width: 100%;
  background: none;
  border-radius: 30px;
  border: none;
}

#virtual-id-one .close-button {
  background: var(--brick-red);
  transition: background 0.5s;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  right: calc(7.5% - 15px);
  top: 25px;
  border: none;
}

#virtual-id-one .close-button div {
  color: var(--white);
  position: absolute;
  right: calc(8% - 9%);
  top: -5%;
  height: 100%;
  width: 100%;
  font-size: 2.5rem;
  display: inline-block;
  line-height: 100%;
}

#virtual-id-one .close-button:hover,
#virtual-id-one .close-button:focus {
  background: var(--brick-red);
  cursor: pointer;
}

/* Error states that can be used for the front or the back of the card */
#virtual-id-one .card-face>div#center {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

#virtual-id-one .card-face .no-account {
  margin: 10px;
  font-size: 15px;
  font-weight: bold;
}

#virtual-id-one .card-face .no-account-detail {
  margin: 10px;
  font-size: 13px;
}

#virtual-id-one .card-face .oops {
  margin: 10px;
  font-size: 18px;
  font-weight: bold;
}

#virtual-id-one .card-face .oops-detail {
  margin: 10px;
  font-size: 15px;
}

#virtual-id-one .card-face section.onecard {
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

#virtual-id-one .card-face#back img.image {
  width: 70px;
  height: 70px;
}

#virtual-id-one .card-face#back img.image-big {
  width: 170px;
  height: 170px;
  margin-top: 0.3em;
}

/* OneCard */

#virtual-id-one section.onecard {
  height: 87%;
  width: 90%;
}

#virtual-id-one p#cougar-bucks-detail,
#virtual-id-one #dining-points-difference,
#virtual-id-one .current-balance div#table {
  margin: 5px 0px;
}