#cas-login-error div#center {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

#cas-login-error .oops {
  margin: 10px;
  font-size: 18px;
  font-weight: bold;
}

#cas-login-error .oops-detail {
  margin: 10px;
  font-size: 15px;
}
