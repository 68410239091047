#onecard-page {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

#onecard-page #hours-button {
  display: flex;
  flex-direction: column;
  text-align: center;
  right: 30px;
  bottom: 30px;
  padding: 15px;
  border-radius: 10px;
  position: fixed;
  transition: background 0.5s;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.13),
    0 0 8px rgba(0, 0, 0, 0.35);
  background: var(--white);
  border: none;
}

#onecard-page #hours-button:hover,
#onecard-page #hours-button:focus {
  background: var(--white);
  cursor: pointer;
}

#onecard-page #hours-image {
  height: 3.5em;
  width: 3.5em;
  margin: auto;
  min-height: 0;
  min-width: 0;
}

#onecard-page .account,
#onecard-page .account .no-plan {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#onecard-page .account .no-plan {
  height: 100%;
  h1 {
    padding-bottom: 5px;
  }
  p {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;
  }
}

/* modal */
#onecard-page #modal-background {
  background: none;
  background-color: #0006;
}

#onecard-page .modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-items: center;
}

#onecard-page .modal-content {
  display: none;
  background: none;
  margin: 40px auto auto;
  width: 85%;
  height: 85%;
}

#onecard-page #hours-iframe {
  height: 100%;
  width: 100%;
  background: none;
  border-radius: 30px;
  border: none;
}

#onecard-page .close-button {
  background: var(--brick-red);
  transition: background 0.5s;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  right: calc(7.5% - 15px);
  top: 25px;
  border: none;
}

#onecard-page .close-button div {
  color: var(--white);
  position: absolute;
  right: calc(8% - 9%);
  top: -5%;
  height: 100%;
  width: 100%;
  font-size: 2.5rem;
  display: inline-block;
  line-height: 100%;
}

#onecard-page .close-button:hover,
#onecard-page .close-button:focus {
  background: var(--brick-red);
  cursor: pointer;
}

/* spinner */
#onecard-page .cliploader-spinner {
  display: block;
}

/* Small Screen */
@media screen and (max-width: 1000px) {
  #onecard-page .onecard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  #onecard-page .onecard #dining-points-difference {
    width: 85%;
  }

  #onecard-page .onecard .image,
  #onecard-page .onecard img {
    min-height: 7em;
    min-width: 7em;
    max-height: 7em;
    max-width: 7em;
  }

  #onecard-page .onecard .current-balance,
  #onecard-page .onecard .current-balance * {
    font-size: 20px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-weight: 600;
  }

  #onecard-page .onecard .current-balance span {
    margin: 0px;
    padding: 0px;
  }

  #onecard-page .onecard .balance-detail {
    color: #888;
    text-align: center;
    font-size: 24px;
  }

  #onecard-page .onecard h1 {
    margin: 10px 0;
    line-height: 1;
    text-align: center;
    color: var(--black);
  }

  #onecard-page .onecard .account,
  #onecard-page .onecard .account .no-plan {
    padding: 20px 0px;
  }

  #onecard-page .onecard .account .no-plan {
    h1 {
      padding-bottom: 5px;
    }
    p {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 10px;
    }
    padding-top: 20%;
  }

  #onecard-page .onecard .account + .account {
    border: none;
    border-top: 2px solid #dedede;
  }

  #onecard-page .onecard .button#add-cougar-bucks {
    margin-top: 15px;
  }

  #onecard-page .onecard .button#hours-button {
    height: 70px;
    width: 70px;
    right: 10px;
    bottom: 10px;
  }

  #onecard-page .onecard #hours-button {
    padding: 10px;
    right: 20px;
    bottom: 20px;
    position: fixed;
    background: #fafafa;
  }

  #onecard-page .onecard #hours-label {
    font-size: 10px;
  }

  #onecard-page .onecard .modal-content {
    margin-top: 50px;
    height: 90%;
  }
}
