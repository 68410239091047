/* Frame 1 */
#chapel-attend {
  font-family: "Inter", sans-serif;
  margin: 0;
  background: #ffffff;
}

#chapel-attend h1,
#chapel-attend h2,
#chapel-attend h3,
#chapel-attend h4,
#chapel-attend h5,
#chapel-attend ul,
#chapel-attend #subtext {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

#chapel-attend #content,
#subtext,
.shade-back,
.content-shade {
  width: 297px;
}

#chapel-attend > #content {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2vh 0;
  margin: auto;
}

/* Chapel Header*/
#chapel-attend > #content h1 {
  font-weight: 700;
  font-size: 30px;
  color: #000000;
  margin-bottom: 15px;
}

#chapel-attend > #content h2 {
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #ca3940;
}

#chapel-attend > #content h3 {
  font-size: 30px;
  margin: 10px 0;
  padding: 0 10px;
  color: #000000;
}

#chapel-attend > #content h4 {
  line-height: 24px;
  margin: 8px 0;
  padding: 10px;
  color: #333133;
}

#chapel-attend > #content h5 {
  font-size: 19px;
  margin: 0;
  color: #333133;
}

#chapel-attend .shade-back {
  height: 100%;
  background: #edebe8;
  border-radius: 20px;
  margin: 10px 0px;
}

#chapel-attend #credit-resources {
  margin-bottom: 20px;
}

#chapel-attend .totals {
  display: flex;
  justify-content: center;
}

#chapel-attend > #content > #subtext {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 10px;
  color: #333133;
}

#chapel-attend ul {
  font-weight: 300;
  font-size: 12px;
  line-height: 32px;
  text-align: left;
  padding-right: 15px;
  color: #000000;
}

#chapel-attend #info ul {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

#chapel-attend #loader {
  padding-bottom: 10px;
}

/* If small turn to widget */
@media screen and (max-height: 390px) {
  #info,
  #credit-resources {
    visibility: hidden;
    display: none;
  }

  #chapel-attend {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #chapel-attend > #content .header {
    display: flex;
    margin: auto;
  }

  #chapel-attend > #content .header h1,
  #chapel-attend > #content .header h5 {
    font-size: 24px;
    font-weight: 700;
    margin: 15px 3px;
    color: #000;
  }
}
