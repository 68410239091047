#maintenance * {
  box-sizing: border-box;
}

#maintenance {
  background-color: #3e3e3f;
  text-align: center;
  color: white;
  font-size: 14px;
  margin: 0;
  display: block;
  /* height: 100%; */
}

#maintenance .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

#maintenance h2 {
  font-size: 30px;
  margin: 0.5em;
  font-weight: 500;
  color: inherit;
  font-family: inherit;
  text-align: center;
}

#maintenance h4 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  color: inherit;
  font-family: inherit;
  text-align: center;
  line-height: 1.1;
}

#maintenance .announcement {
  width: 90%;
  max-width: 700px;
  margin: auto;
}

#maintenance .announcement>p {
  color: white;
  font-size: 16px;
  margin: 0 0 10px;
  display: block;
}

#maintenance .header {
  display: flex;
  padding: 3em;
}

#maintenance .column {
  flex: 33.3%;
  padding: 0 4px;
  width: 100%;
}

#maintenance #logo {
  max-width: 250px;
  float: left;
  width: 100%;
}

#maintenance #maintimg {
  max-width: 250px;
  margin: auto;
}

#maintenance table {
  border: 1px solid white;
  padding: 5px 0;
  width: 90%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  table-layout: fixed;
  background: rgba(0, 0, 0, 0.5);
  border-spacing: 0;
  border-collapse: collapse;
  display: table;
}

#maintenance tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

#maintenance tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

#maintenance th {
  border: 1px solid white;
  padding: 5px 0;
}

#maintenance .cent {
  text-align: center;
}

#maintenance a {
  color: white;
  text-decoration: underline;
}

#maintenance html {
  min-height: 100%;
}

#maintenance .banner-div {
  color: black;
  background-color: #ffe157;
  font-size: 0.875rem;
  font-family: "IBM Plex Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 450;
  line-height: 1.5;
  top: 0px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  padding-left: 10px;
}

#maintenance .banner-text {
  margin: auto 0px;
}

#maintenance .banner-x-button {
  background: none;
  border: none;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 1000px) {
  #maintenance .header {
    flex-flow: column;
  }

  #maintenance #logo {
    float: none;
    max-width: 100px;
    margin-bottom: 1em;
  }

  #maintenance #maintimg {
    max-width: 150px;
  }
}