/* Global Search Page */
.global-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
  margin: auto;
  font-size: 16px;
  font-family: 'Helvetica',
    Arial,
    sans-serif;
}

.global-search h1 {
  font-size: 40px;
}

/* iFrame-specific styles */
.global-search.iframe-1 {
  margin: 0;
  padding: 10px;
  align-items: start;
  justify-content: left;
}

.global-search.iframe-1 #search-container {
  width: 100%;
}

.global-search.iframe-1 #search-div {
  margin: 0 !important;
  padding: 16px 0px 16px 50px;
}

.global-search.iframe-1 #displayFeatures {
  margin: 0px 0px 0px 40px;
}

.global-search.iframe-1 #page-header {
  display: none;
}

/* End: iFrame-specific styles */

.global-search #search-container {
  width: 80%;
}

.global-search #search-div {
  margin: auto !important;
  padding: 20px 50px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow:
    /*right-offset*/
    3px
    /*bottom-offset*/
    3px
    /*blur-radius*/
    5px
    /*thickness?*/
    -2px
    /*color*/
    #ccc;
  background-repeat: no-repeat;
  background-position: 1px;
}

.global-search #input-title {
  margin-right: 8px;
}

.global-search #search-div #queryText {
  background: transparent;
  color: var(--black);
  width: 100%;
  border: none;
  font-weight: 500;
  font-size: 20px;
}

.global-search #search-div #queryText:focus {
  outline: none;
}

.global-search ::placeholder {
  color: var(--black);
  opacity: 1;
}

.global-search :-ms-input-placeholder {
  color: var(--black);
}

.global-search ::-ms-input-placeholder {
  color: var(--black);
}

.global-search #displayFeatures {
  margin: 0px 40px 50px 40px;
}

.global-search #displayFeatures .results-header {
  color: var(--brick-red);
  font-size: 30px;
  margin: 20px 0;
}

.global-search #displayFeatures a {
  color: var(--black);
  line-height: 40px;
  width: 100%;
  padding: 5px 0;
  text-align: left;
  display: block;
  background-size: 20px 20px;
  text-decoration: none;
}

.global-search #search-result {
  font-size: 20px;
  overflow: hidden !important;
}

.global-search .show-more-less {
  color: #777;
  font-size: 20px;
  margin-top: 15px;
}

.global-search .show-more-less:hover,
.global-search .show-more-less:focus {
  cursor: pointer;
  font-weight: 500;
}

.global-search button.show-more-less {
  text-align: center;
  border: none;
  padding: 0px;
  background-color: var(--white);
}

.global-search .show-more-less p {
  margin: 0;
}

.global-search #search-result:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.global-search #search-result #link {
  line-height: 1.4;
  color: var(--brick-red);
  width: inherit;
}

.global-search #search-result #title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.global-search #search-result #title:hover {
  text-decoration: underline;
}

.global-search #search-result #snippet {
  font-size: 16px;
  color: #606060;
  line-height: 1.4;
  width: inherit;
  text-overflow: ellipsis;
  max-width: 800px;
}

@media screen and (max-width: 464px) {

  .global-search #displayFeatures {
    margin: 0px 10px;
  }
}

@media screen and (max-width: 1000px) {
  .global-search h1 {
    width: 100%;
    text-align: center;
  }
}

/* End of Global Search Page */