.stylized-index-page * {
  font-family: Helvetica, Verdana, sans-serif;
  color: #222;
}

.stylized-index-page h1 {
  font-size: 48px;
  margin: 10px 20px;
  padding: 0;
}

.stylized-index-page ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.stylized-index-page li {
  font-weight: 200;
  font-size: 20px;
  line-height: 1.5;
  border-bottom: 1px solid #ccc;
}

.stylized-index-page li:last-child {
  border: none;
}

.stylized-index-page li a {
  text-align: left;
  text-decoration: none;
  display: block;
  padding: 8px 40px;
  -webkit-transition:
    font-size 0.3s ease,
    background-color 0.3s ease;
  -moz-transition:
    font-size 0.3s ease,
    background-color 0.3s ease;
  -o-transition:
    font-size 0.3s ease,
    background-color 0.3s ease;
  -ms-transition:
    font-size 0.3s ease,
    background-color 0.3s ease;
  transition:
    font-size 0.3s ease,
    background-color 0.3s ease;
}

.stylized-index-page li a:hover,
.stylized-index-page li a:focus {
  font-size: 30px;
  background: #f6f6f6;
  color: #900;
}
