/* One Card Page */
.onecard * {
  font-size: 16px;
}

.onecard {
  display: flex;
  width: 100%;
}

.onecard body {
  margin: 0;
}

.onecard p {
  margin: 0;
}

.onecard h1 {
  font-size: 25px;
  line-height: 1;
  text-align: center;
  color: var(--black);
}

.onecard hr {
  width: 50%;
  margin: 5px 0;
}

.onecard h1#name-header {
  font-size: 40px;
  line-height: 1;
  text-align: center;
  color: var(--brick-red);
}

.onecard .image,
.onecard img {
  height: 180px;
  width: 180px;
  max-height: 180px;
  max-width: 180px;
}

.onecard .account,
.onecard .account .cougar-bucks {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.onecard .account + .account {
  border-left: 1px solid #dedede;
}

.onecard .current-balance,
.onecard .current-balance * {
  font-size: 28px;
  margin-bottom: 12px;
  color: var(--black);
  text-align: center;
  font-weight: 600;
  width: 11em;
}

.onecard #dining-points-difference,
.onecard p#dining-points-detail {
  text-align: center;
  margin: 10px 0;
}

.onecard .current-balance .td {
  display: table-row;
}

.onecard .current-balance .td div {
  display: table-cell;
  padding: 0.2em 0;
}

.onecard .current-balance div#table {
  margin: 10px 0;
}

.onecard .current-balance #table .align-right {
  text-align: right;
}

.onecard .current-balance #table .align-left {
  text-align: left;
  width: 75%;
}
