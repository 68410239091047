.container {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.image-with-button {
  object-fit: cover;
  width: 400px;
  height: 220px;
  border-radius: 10px;
}
