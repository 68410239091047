#workspace-widget {
  overflow: hidden;
  height: 148px;
  padding: 10px;
}
#workspace-widget #sites-container {
  display: flex;
  flex-direction: column;
}

#workspace-widget #sites-container > div {
  justify-content: space-between;
  display: flex;
}
