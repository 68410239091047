#service-credit-form {
  width: 70%;
  margin: auto;
}

#service-credit-form input {
  height: 30px;
  width: 400px;
}

#service-credit-form select {
  height: 30px;
  width: 400px;
}

#service-credit-form input[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

#service-credit-form input[type="submit"] {
  height: auto;
  width: auto;
}

#service-credit-form .hidden {
  display: none;
}

#service-credit-form h2 {
  color: #900;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

#editor {
  border: 1px solid #ccc;
}

#counter {
  border: 1px solid #ccc;
  border-width: 0px 1px 1px 1px;
  padding: 5px 15px;
  text-align: right;
}

#button {
  display: flex;
  justify-content: space-around;
}

#button-container,
#checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#row-button {
  margin-right: 15px;
}

#service-credit-form label {
  display: inline-block;
  width: 100px;
  margin-right: 10px;
}

#read-and-agree-label {
  width: 50%;
}

#title {
  margin-right: 10px;
}

#label-student,
#label-ministry,
#supervisor-label,
#service-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
  #service-credit-form {
    width: 90%;
  }

  #show-services-button {
    font-size: 15px;
  }

  #service-credit-form input[type="checkbox"] {
    height: 25px;
    width: 25px;
    flex: none;
  }

  #read-and-agree-label {
    flex: 1;
  }

  #label-student,
  #label-ministry,
  #supervisor-label,
  #service-label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  #service-credit-form label {
    width: 125px;
  }
}