/* Resources Page */
#resource-page {
  margin: 0;
  background-color: var(--white);
}

#resource-page.panel-resources {
  margin-left: 18px;
  max-height: 0;
  overflow: hidden;
}

#resource-page.direct-link hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 5px solid var(--brick-red);
  margin: 1em 0;
  padding: 0;
}

/* End of Resources Page */
