#trolley-eta-widget {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--bone-white);
}

#trolley-eta-widget #trolley-eta-widget__container {
  display: flex;
  padding: 20px;
  width: 80%;
  max-width: 350px;
  color: var(--black);
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 20px;
}

#trolley-eta-widget #trolley-eta-widget__container h3 {
  color: var(--light-brick);
  padding: 0px;
  margin: 0px;
}

#trolley-eta-widget #trolley-eta-widget__error {
  flex-direction: column;
}