#tablebuild table {
  width: 90%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(0, 0, 0, 0.5);
}
#tablebuild table th {
  border: 1px solid var(--white);
  padding: 5px 0;
}
#tablebuild .cent {
  text-align: center;
}
