/* styles taken from apu.edu */
:root {
  --white: #ffffff;
  --bone-white: #eceae8;
  --black: #000000;
  --light-brick: #ca3940;
  --brick-red: #9d2e34;
  --dark-brick: #7d2932;
  --vibrant-red: #e4002b;
  --slate-black: #252425;
  --alert-yellow: #efd920;
  --link-color: var(--dark-brick);
}

/* Fonts */
@font-face {
  font-family: Inter;
  src: url("./fonts/Inter/Inter-VariableFont_slnt,wght.ttf")
    format("truetype-variations");
  font-weight: 1 999;
}
/* End of Fonts */

/* Global Styles */
#root {
  height: 100vh;
}

* {
  outline: unset;
}

*:not(i) {
  font-family: Inter, Helvetica, "Helvetica Neue", Arial, sans-serif;
}

body {
  overflow: auto;
}
/* End of Global Styles */

/* Input Box */
.input-box {
  color: #303030;
  border: 1px solid rgba(123, 123, 123, 0.7);
}

.input-box:focus {
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.13),
    0 0 8px rgba(153, 0, 0, 0.35);
}
/* End of Input Box */

/* Custom Alert Banner */
.custom-alert.banner {
  padding: 20px;
  background-color: #5aa3f5;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 30px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

.custom-alert.banner p {
  margin: 0;
}
/* End of Custom Alert Banner */

/* Loading Spinner */
.cliploader-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/* End of Loading spinner */

/* Divider Div */
div.divider {
  background: #ccc !important;
  margin: auto;
}

div.divider.horizontal {
  height: 1px;
  width: 100%;
}

div.divider.vertical {
  height: 100%;
  width: 1pt;
}
/* End of Divider Div */
