section#service-credits {
  height: 100%;
}

/* Service Credits Page */
#service-credits-table .style8 {
  display: none;
}

#service-credits-table .style6 {
  text-align: left;
  padding-left: 1em;
}

#service-credits-table .style4 {
  color: #ff0000;
  font-weight: bold;
}

#service-credits-table .style5 {
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
  margin-right: 10px;
}

#service-credits-table .style5a {
  font-weight: bold;
}

#service-credits-table .style5b {
  font-weight: bold;
  color: #009900;
}

#service-credits-table .style5c {
  font-weight: bold;
  color: #ff0000;
}

#service-credits-table .style9 {
  font-weight: bold;
}

#service-credits-table .style2 {
  color: #009900;
  font-weight: bold;
}

#service-credits-table .style1 {
  margin-left: 10px;
}

#service-credits-table #questions {
  margin: 15px;
  text-align: center;
}

#service-credits-key {
  width: 100%;
  overflow: hidden;
  background: #ddd;
}

#service-credits-key ul {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 4px;
}

#service-credits-key img {
  display: block;
  margin: auto;
}

#service-credits-key li {
  display: block;
  flex: 0 1 auto;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

#service-credits-table table * {
  font-size: 16px;
  padding: 2px 0 0 8px;
}

#service-credits-table {
  margin: auto;
  width: 735px;
}

#service-credits-table table {
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

#service-credits-table tr:nth-of-type(odd) {
  background: #efefef;
}

#service-credits-table tr:first-of-type {
  background: #ddd;
  height: 40px;
}

#service-credits-table td {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-size: 20px;
  text-align: center;
}

#service-credits-table table a {
  color: #900;
  text-decoration: underline;
}

#service-credits-table table a:hover,
#service-credits-table table a:focus {
  color: #f00;
}

#service-credits-table th {
  text-align: center;
}

#service-credits-table .status-icon {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 0;
}

#service-credits-table #new_scr {
  display: block;
  margin: 20px auto;
  font-size: 16px;
  width: 300px;
  text-decoration: none;
  text-align: center;
  line-height: 40px;
}

#service-credits-table #service-credits-no-req {
  margin: 40px auto;
  max-width: 90%;
}

@media screen and (max-width: 750px) {
  #service-credits-table {
    width: 95%;
  }

  #service-credits-table #new_scr {
    width: 90%;
  }

  #service-credits-table::-webkit-scrollbar {
    display: none;
  }

  #service-credits-key ul {
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: 19px;
  }

  #service-credits-key li {
    padding-top: 5px;
  }
}

#service-credits-table h2 {
  text-align: center;
}

@media screen and (max-width: 370px) {
  #service-credits-table table * {
    font-size: 14px;
    padding: 0 0 0 2px;
  }

  #service-credits-table #new_scr {
    width: 280px;
  }

  #service-credits-table #new_scr * {
    font-size: 12px;
  }

  #service-credits-table h2 {
    font-size: 16px;
  }

  #service-credits-key ul {
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: 15px;
  }

  #service-credits-key li {
    padding-top: 5px;
  }
}

/* End of Service Credits Page */