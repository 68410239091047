.button,
.button-sec {
  font: var(--body);
  font-weight: 700;
  align-self: center;
  margin-top: 20px;
  width: fit-content;
  height: fit-content;
  line-height: 40px;
  padding: 0 20px 0 20px;
  color: #fff;
  background: var(--light-brick);
  display: block;
  border: 2px solid var(--light-brick);
  border-radius: 40px;
  font-size: 16px;
  transition: background 0.5s;
  text-decoration: none;
}

.button:hover,
.button:focus {
  background: var(--brick-red);
  cursor: pointer;
}

.button-sec:hover,
.button-sec:focus {
  background: var(--light-brick);
  color: var(--white);
  cursor: pointer;
}

.button {
  background: var(--light-brick);
}

.button-sec {
  background: var(--white);
  color: var(--light-brick);
}
