/* Accordion Page */

#accordion button.toggle-all {
  width: 8em;
  margin: 15px;
  padding: 10px 15px;
  background: var(--brick-red);
  color: var(--white);
  border: none;
  border-radius: 5px;
}

#accordion button.toggle-all:hover,
#accordion button.toggle-all:focus {
  background: var(--dark-brick);
}

#accordion #resource-inner {
  font-family: "Helvetica", Arial, sans-serif;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  background: var(--white);
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#accordion button {
  font-family: "Helvetica", Arial, sans-serif;
  background: var(--white);
  border: none;
}

#accordion .category {
  font-family: "Helvetica", Arial, sans-serif;
  width: 100%;
  display: flex;
  text-align: left;
  outline: none;
  cursor: pointer;
}
#accordion .category:hover {
  background: var(--bone-white);
  transition: background 0.3s ease;
}

#accordion .category-icon {
  background: none;
  height: 50px;
  width: 40px;
  margin-right: 10px;
  flex-direction: row;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  text-align: center;
  display: flex;
}

#accordion .category-icon img {
  margin: auto;
  height: 40px;
}

#accordion .icon {
  transform: rotate(90deg);
  transition: 0.2s ease-in-out;
}

#accordion .active .icon {
  transform: rotate(180deg);
}

#accordion .category-text {
  font-family: "Helvetica", Arial, sans-serif;
  width: 90%;
  height: 50px;
  flex-direction: row;
  display: table;
}

#accordion .inner-label {
  font-family: "Helvetica", Arial, sans-serif;
  display: table-cell;
  vertical-align: middle;
}

#accordion .inner-label.title {
  font-family: "Helvetica", Arial, sans-serif;
  color: var(--black);
  font-size: 15px;
  font-weight: bold;
  transition: 0.4s;
}

#accordion .active .inner-label.title {
  color: var(--brick-red);
}

#accordion .panel-resources {
  margin-left: 18px;
  background-color: var(--white);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

/* Class */

.accordion li {
  width: 100%;
  background-image: url("../images/icons/icon-right-arrow-black.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 20px 20px;
}

.accordion ul {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

.accordion ul:last-of-type {
  margin-bottom: 15px;
}

.accordion a {
  box-sizing: border-box;
  color: var(--black);
  width: 100%;
  display: inline-block;
  padding: 10px 10px 10px 0px;
  margin-bottom: 0;
  text-decoration: none;
  border: 2px solid var(--white);
  border-bottom: 1px solid var(--bone-white);
}
.accordion a:focus,
.accordion a:hover {
  outline: 1px dashed var(--vibrant-red);
  outline-offset: -2px;
}

/* End of accordion Page */
