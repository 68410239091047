div#center-oops {
  max-width: 100%;
  max-height: 100%;
  min-height: 90%;
  min-width: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

#center-oops .oops {
  margin: 10px;
  font-size: 18px;
  font-weight: bold;
}
#center-oops .oops-detail {
  margin: 10px;
  font-size: 15px;
}
